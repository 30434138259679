exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-staff-web-development-js": () => import("./../../../src/pages/code-staff-web-development.js" /* webpackChunkName: "component---src-pages-code-staff-web-development-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-wordpress-js": () => import("./../../../src/pages/custom-wordpress.js" /* webpackChunkName: "component---src-pages-custom-wordpress-js" */),
  "component---src-pages-devin-mallonee-js": () => import("./../../../src/pages/devin-mallonee.js" /* webpackChunkName: "component---src-pages-devin-mallonee-js" */),
  "component---src-pages-ecommerce-development-js": () => import("./../../../src/pages/ecommerce-development.js" /* webpackChunkName: "component---src-pages-ecommerce-development-js" */),
  "component---src-pages-ecommerce-seo-js": () => import("./../../../src/pages/ecommerce-seo.js" /* webpackChunkName: "component---src-pages-ecommerce-seo-js" */),
  "component---src-pages-free-web-design-js": () => import("./../../../src/pages/free-web-design.js" /* webpackChunkName: "component---src-pages-free-web-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logo-design-js": () => import("./../../../src/pages/logo-design.js" /* webpackChunkName: "component---src-pages-logo-design-js" */),
  "component---src-pages-product-categories-js": () => import("./../../../src/pages/product-categories.js" /* webpackChunkName: "component---src-pages-product-categories-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-sacramento-seo-js": () => import("./../../../src/pages/sacramento-seo.js" /* webpackChunkName: "component---src-pages-sacramento-seo-js" */),
  "component---src-pages-shopify-developer-js": () => import("./../../../src/pages/shopify-developer.js" /* webpackChunkName: "component---src-pages-shopify-developer-js" */),
  "component---src-pages-woocommerce-development-js": () => import("./../../../src/pages/woocommerce-development.js" /* webpackChunkName: "component---src-pages-woocommerce-development-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-categories-template-js": () => import("./../../../src/templates/product-categories-template.js" /* webpackChunkName: "component---src-templates-product-categories-template-js" */),
  "component---src-templates-products-template-js": () => import("./../../../src/templates/products-template.js" /* webpackChunkName: "component---src-templates-products-template-js" */),
  "component---src-templates-service-areas-js": () => import("./../../../src/templates/service-areas.js" /* webpackChunkName: "component---src-templates-service-areas-js" */)
}

